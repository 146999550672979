// This overrides some styling properties of the "Light" flatpickr theme
// sass-lint:disable force-element-nesting force-attribute-nesting force-pseudo-nesting no-vendor-prefixes no-important class-name-format

$vl-flatpickr-today: #f4f4f4;

.flatpickr-monthSelect-month {
  &.selected {
    // reset all styles for theme
    &,
    &.prevMonthDay,
    &.nextMonthDay {
      background: 0;
      color: $vl-science-blue;
      border-color: transparent;
      font-weight: 500;

      &:hover,
      &:focus {
        background: $vl-accent-color;
        color: $vl-text-color;
        border-color: transparent;
      }
    }

    &.startRange,
    &.endRange {
      background: $vl-accent-color;
      border-color: $vl-accent-color;
      color: $vl-text-color;
    }
  }

  &.selected,
  &.startRange,
  &.endRange {
    background-color: $vl-accent-color;
    border-color: $vl-accent-color;

    &.prevMonthDay,
    &.nextMonthDay {
      background-color: $vl-accent-color;
      border-color: $vl-accent-color;
    }

    &.startRange {
      & + .endRange {
        box-shadow: -10px 0 0 $vl-accent-color;
      }
    }
  }

  &:hover,
  &:focus {
    // reset all styles for theme
    color: $vl-curious-blue !important;
    background: $vl-athens-gray !important;
    border-color: transparent !important;
    font-weight: 500;
  }
}
